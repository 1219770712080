.px-16,
.px-2 {
    padding: 0 16px;
}

.p-0 {
    padding: 0;
}

.pt-1 {
    padding-top: 8px;
}

.p-16,
.p-2 {
    padding: 16px;
}

.px-20 {
    padding: 0 20%;
}

.py-12 {
    padding: 12px 0;
}

.py-16 {
    padding: 16px 0;
}


/* PADDING TOP */

.pt-8 {
    padding-top: 8px;
}

.pt-16,
.pt-2 {
    padding-top: 16px;
}

.pt-3,
.pt-24 {
    padding-top: 24px;
}

.pt-40 {
    padding-top: 40px;
}


/* PADDING LEFT */

.pl-1 {
    padding-left: 8px;
}

.pl-16,
.pl-2 {
    padding-left: 16px;
}


/* MARGIN */

.m-0 {
    margin: 0;
}

.m-8 {
    margin: 8px;
}

.m-16 {
    margin: 16px;
}

.mt-8,
.mt-1 {
    margin-top: 8px;
}

.mt-16,
.mt-2 {
    margin-top: 16px;
}

.mt-24,
.mt-3 {
    margin-top: 24px;
}

.mb-16,
.mb-2 {
    margin-bottom: 16px;
}

.my-8,
.my-1 {
    margin: 8px 0;
}

.my-16,
.my-2 {
    margin: 16px 0;
}

.mr-1 {
    margin-right: 8px;
}

.mr-2 {
    margin-right: 16px;
}