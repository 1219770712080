@import "./default.css";
@import "./flex.css";
@import "./PaddingMargin.css";
:root {
  --primary: #6200ee;
  --blue: #3d5a80;
  --blue-light: #98c1d9;
  --orange: #ee6c4d;
  --black: #293241;
}

body {
  overscroll-behavior: contain;
}

a {
  text-decoration: none;
  color: unset;
}

.f-white {
  color: white;
}

.f-dark {
  color: var(--black);
}

.f-blue {
  color: var(--blue);
}

.f-orange {
  color: var(--orange);
}

.bg-orange {
  background-color: var(--orange);
}

.b-orange {
  border-color: var(--orange);
}

.bg-blue {
  background-color: var(--blue);
}

.img-24 {
  width: 24px;
  height: 24px;
}

.img-32 {
  width: 48px;
  height: 48px;
  padding: 4px;
}

.img-48 {
  width: 48px;
  height: 48px;
}

.img-64 {
  width: 64px;
  height: 64px;
}

.img-fill {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.coin {
  border-radius: 50%;
}

.overflowx-hidden {
  overflow-x: hidden;
}

.flex-basis {
  flex-basis: 20%;
}

.flex-basis > button > img {
  background-color: #fff;
  width: "32px";
  border-radius: 50%;
}

.bg-img-g {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1680' height='2320' preserveAspectRatio='none' viewBox='0 0 1680 2320'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1056%26quot%3b)' fill='none'%3e%3crect width='1680' height='2320' x='0' y='0' fill='rgba(246%2c 238%2c 227%2c 1)'%3e%3c/rect%3e%3cpath d='M922.48-328.38C588.19 137.72 745.86 1759.16 304.71 1881.22-136.45 2003.28-68.91 1124.27-313.07 1022.82' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1247.95-267.19C947.96-30.06 1144.24 853.52 613.45 1124.57 82.65 1395.62 196.24 1701.32-21.06 1750.97' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1144.67-172.34C818.61 56.12 1006.24 995.32 431.69 1251.68-142.85 1508.04-44.61 1855.1-281.28 1901.28' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1035.21-422.14C818.2-49.8 984.12 1233.13 648.64 1257.82 313.16 1282.51 171.57 369.34-124.49 260.22' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1602.07-401.73C1423.59-371.25 1330.29 67.86 1051.19 68.45 772.09 69.04 775.75-221.55 500.3-221.55 224.86-221.55 104.82 59.77-50.58 68.45' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1056'%3e%3crect width='1680' height='2320' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e"); */
  /* background-size: 100vw 100vh; */
}

.text-uppercase{
  text-transform: uppercase;
}