.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.fh-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.justify-even {
    justify-content: space-evenly;
}

.justify-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-start {
    align-items: flex-start;
}

.scroll-x-auto {
    overflow-x: auto;
}

.scroll-y-auto {
    overflow-y: auto;
}

.flex-grow {
    flex-grow: 1;
}

.flex-nogrow {
    flex-grow: 0;
}

.flex-noshrink {
    flex-shrink: 0;
}

.fg-16 {
    gap: 16px;
}