@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

/* 
.f-error {
    color: #b00020;
    font-size: 12px;
}

.f-center {
    text-align: center;
}


} */

.f12 {
  font-size: 0.75rem;
}

.f-uppercase {
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .noscroll::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.noscroll::-webkit-scrollbar-track {
    background: red;
}

.noscroll ::-webkit-scrollbar-thumb {
    background: #888;
}

.noscroll ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

.notouch {
  /* pointer-events: none; */
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.fh-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.justify-even {
    justify-content: space-evenly;
}

.justify-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-start {
    align-items: flex-start;
}

.scroll-x-auto {
    overflow-x: auto;
}

.scroll-y-auto {
    overflow-y: auto;
}

.flex-grow {
    flex-grow: 1;
}

.flex-nogrow {
    flex-grow: 0;
}

.flex-noshrink {
    flex-shrink: 0;
}

.fg-16 {
    grid-gap: 16px;
    gap: 16px;
}
.px-16,
.px-2 {
    padding: 0 16px;
}

.p-0 {
    padding: 0;
}

.pt-1 {
    padding-top: 8px;
}

.p-16,
.p-2 {
    padding: 16px;
}

.px-20 {
    padding: 0 20%;
}

.py-12 {
    padding: 12px 0;
}

.py-16 {
    padding: 16px 0;
}


/* PADDING TOP */

.pt-8 {
    padding-top: 8px;
}

.pt-16,
.pt-2 {
    padding-top: 16px;
}

.pt-3,
.pt-24 {
    padding-top: 24px;
}

.pt-40 {
    padding-top: 40px;
}


/* PADDING LEFT */

.pl-1 {
    padding-left: 8px;
}

.pl-16,
.pl-2 {
    padding-left: 16px;
}


/* MARGIN */

.m-0 {
    margin: 0;
}

.m-8 {
    margin: 8px;
}

.m-16 {
    margin: 16px;
}

.mt-8,
.mt-1 {
    margin-top: 8px;
}

.mt-16,
.mt-2 {
    margin-top: 16px;
}

.mt-24,
.mt-3 {
    margin-top: 24px;
}

.mb-16,
.mb-2 {
    margin-bottom: 16px;
}

.my-8,
.my-1 {
    margin: 8px 0;
}

.my-16,
.my-2 {
    margin: 16px 0;
}

.mr-1 {
    margin-right: 8px;
}

.mr-2 {
    margin-right: 16px;
}
:root {
  --primary: #6200ee;
  --blue: #3d5a80;
  --blue-light: #98c1d9;
  --orange: #ee6c4d;
  --black: #293241;
}

body {
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

a {
  text-decoration: none;
  color: unset;
}

.f-white {
  color: white;
}

.f-dark {
  color: #293241;
  color: var(--black);
}

.f-blue {
  color: #3d5a80;
  color: var(--blue);
}

.f-orange {
  color: #ee6c4d;
  color: var(--orange);
}

.bg-orange {
  background-color: #ee6c4d;
  background-color: var(--orange);
}

.b-orange {
  border-color: #ee6c4d;
  border-color: var(--orange);
}

.bg-blue {
  background-color: #3d5a80;
  background-color: var(--blue);
}

.img-24 {
  width: 24px;
  height: 24px;
}

.img-32 {
  width: 48px;
  height: 48px;
  padding: 4px;
}

.img-48 {
  width: 48px;
  height: 48px;
}

.img-64 {
  width: 64px;
  height: 64px;
}

.img-fill {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.coin {
  border-radius: 50%;
}

.overflowx-hidden {
  overflow-x: hidden;
}

.flex-basis {
  flex-basis: 20%;
}

.flex-basis > button > img {
  background-color: #fff;
  width: "32px";
  border-radius: 50%;
}

.bg-img-g {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1680' height='2320' preserveAspectRatio='none' viewBox='0 0 1680 2320'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1056%26quot%3b)' fill='none'%3e%3crect width='1680' height='2320' x='0' y='0' fill='rgba(246%2c 238%2c 227%2c 1)'%3e%3c/rect%3e%3cpath d='M922.48-328.38C588.19 137.72 745.86 1759.16 304.71 1881.22-136.45 2003.28-68.91 1124.27-313.07 1022.82' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1247.95-267.19C947.96-30.06 1144.24 853.52 613.45 1124.57 82.65 1395.62 196.24 1701.32-21.06 1750.97' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1144.67-172.34C818.61 56.12 1006.24 995.32 431.69 1251.68-142.85 1508.04-44.61 1855.1-281.28 1901.28' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1035.21-422.14C818.2-49.8 984.12 1233.13 648.64 1257.82 313.16 1282.51 171.57 369.34-124.49 260.22' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1602.07-401.73C1423.59-371.25 1330.29 67.86 1051.19 68.45 772.09 69.04 775.75-221.55 500.3-221.55 224.86-221.55 104.82 59.77-50.58 68.45' stroke='rgba(238%2c 108%2c 77%2c 0.2)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1056'%3e%3crect width='1680' height='2320' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e"); */
  /* background-size: 100vw 100vh; */
}

.text-uppercase{
  text-transform: uppercase;
}
